let category = params.get('category');
let subCategory = params.get('subCategory');
let appliedFiltersArray = params.get('filters');

document.body.classList.remove('dresses', 'trousers');
document.body.classList.add(category);

if(subCategory == null){
  subCategory = 'all';
}

if(category == 'shirts' || category == 'jackets' || category == 'skirts'){
  subCategory = 'all';
}

if(category == 'trousers'){
  // set dropdown
  document.querySelector('#categorySelector').value = 'trousers';
}

if(subCategory != 'all'){
  document.querySelector('[name="' + subCategory + '"').checked = true;
  updateFilterCount();
}

if(document.body.classList.contains('search')){

    let myCurationData = JSON.parse(localStorage.getItem("myCuration")); // Don't need as defined globally
  
    if(document.body.classList.contains('savedUser')){

      let myCurationToggle = document.querySelector('#myCuration');
      myCurationToggle.disabled = false;

      let curatedItems = filterItems(myCurationData, true);
  
      if(myCurationData.curatedFilter == "false"){

        populateSearch(items, curatedItems);

      } else {
        // If no local state saved for curated filter, create it and set to true
        if(!myCurationData.curatedFilter){
          myCurationData['curatedFilter'] = 'true';
          saveData(myCurationData);
        }
        document.querySelector('#myCuration').checked = true;
        filterItems(myCurationData);
      }
      
    } else {
  
      populateSearch(items);
  
    }
  

    if(appliedFiltersArray != null){
      appliedFiltersArray = appliedFiltersArray.split(' ');
    } else {
      appliedFiltersArray = [];
    }
    
  
    if(category == null){
      
      if(!document.body.classList.contains('showShortlist')){
        window.location.replace(`${appBaseProtocol}//${appBaseUrl}/my-curation/search/?category=dresses`);
      }
      
    }

    if(document.body.classList.contains('showShortlist')){

      if(myCurationData.shortlist.length > 0){

        document.body.classList.add('showShortlist');
        document.body.classList.remove('filtersOpen');
        
        // https://stackoverflow.com/questions/48844452/filter-array-of-objects-by-array-of-ids
        // https://stackoverflow.com/questions/53648296/how-to-filter-a-array-of-objects-but-change-order
        let latestFirstShortlist = myCurationData.shortlist.reverse();
        shortlist = latestFirstShortlist.map(n => items.find(i => i.ref ===n));

        let curatedItems = filterItems(myCurationData, true);
        populateSearch(shortlist, curatedItems);

      }
    }
  
    

    if(appliedFiltersArray != null){

      var allFilters = document.querySelectorAll('.filter-pill input');

      for (var i = 0; i < allFilters.length; i++) {
          if(exists(allFilters[i].id, appliedFiltersArray)){
            allFilters[i].checked = true;
          }
      }

      updateFilterCount();

      let currentItems = document.querySelectorAll('.result-item');
      loopItems(currentItems, appliedFiltersArray, false);
      appliedFiltersArray = appliedFiltersArray.join(' ');
      appliedFiltersArray = appliedFiltersArray.trim();
      document.querySelector('[data-item-filters]').setAttribute('data-item-filters', appliedFiltersArray);

    }


  
};

// Check if value exists in array
function exists(value, array) {
  return array.some(e => e === value);
}

// Populate Search Items View
function populateSearch(items, curatedItems){

  let categoryItems = items;

  if(!document.body.classList.contains('showShortlist')){
    let filterCategory = document.getElementById('categorySelector').value;
    categoryItems = items.filter((x)=>x.itemCategory === filterCategory);
    // Handle unavailable items
    categoryItems = categoryItems.filter((x)=>x.unavailable === false);
    // console.log(categoryItems);
  }

  let itemListContainer = document.querySelector('.result-items');

  // Clear the results container...
  itemListContainer.innerHTML = '';

  if(categoryItems.length < 1){

    itemListContainer.innerHTML = `<div style="width:100%;max-width:50rem;margin:0 auto;">
    <svg width="64" height="64" viewBox="0 0 24 24" style="display:block;margin:0 auto;"><path d="M12 4A3.5 3.5 0 0 0 8.5 7.5H10.5A1.5 1.5 0 0 1 12 6A1.5 1.5 0 0 1 13.5 7.5A1.5 1.5 0 0 1 12 9C11.45 9 11 9.45 11 10V11.75L2.4 18.2A1 1 0 0 0 3 20H21A1 1 0 0 0 21.6 18.2L13 11.75V10.85A3.5 3.5 0 0 0 15.5 7.5A3.5 3.5 0 0 0 12 4M12 13.5L18 18H6Z" /></svg>
    <p>Oh no, we're so sorry, but based on your supplied information we don't currently have any curated items for you in this clothing category!</p>
    <p>First and foremost - please check that you have completed the <a href="/my-curation/#five">My Curation questionnaire</a> with your correct measurements as this may be the simple reason why you're not seeing any items here.</p>
    <p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSdH7QZna-yDPy2hZd5OXWua0fmVMMIvz1ODKQM-j844EczfWw/viewform" target="_blank">get in touch</a> to let us know that you've had this experience and so we can learn a bit more about you to try to correct this.</p>
    <p style="text-align:center; margin-top:2.4rem;"><a href="https://docs.google.com/forms/d/e/1FAIpQLSdH7QZna-yDPy2hZd5OXWua0fmVMMIvz1ODKQM-j844EczfWw/viewform" target="_blank" class="button">Get in Touch</a></p>
    </div>`;

    updateShortlistCount();

  } else {

    itemListContainer.innerHTML = '';
  
    for(let i = 0; i < categoryItems.length; i++){

      let SubCategories = categoryItems[i].subCategories.join(" ");
      let filters = categoryItems[i].filters.join(" ");
      let curationClass = "";
      let flagClass = "";
      let unavailableClass = "";

      if(document.body.classList.contains('validatedUser')){
        if(myCurationData.disliked != null){
          if(myCurationData.disliked.includes(`${ categoryItems[i].ref }`)){
            curationClass = ' disLiked';
          }
        }
        
        if(myCurationData.shortlist){
          if(myCurationData.shortlist.includes(`${ categoryItems[i].ref }`)){
            curationClass = ' shortListed';
          }
        }
      }

      if(curatedItems != undefined){
        if(curatedItems.includes(`${ categoryItems[i].ref }`)){
          flagClass = ' curatedItem';
        }
      }

      if(categoryItems[i].unavailable){
        unavailableClass = ' unAvailable';
      }

      let itemObject = `
      <div class="result-item ${curationClass}${flagClass}${unavailableClass}" data-item-ref="${ categoryItems[i].ref }" data-category="${ categoryItems[i].itemCategory }"  data-subCategories="${ SubCategories }" data-filters="${ SubCategories } ${ filters }">
        <div class="result-item__controls">
          <div class="result-item__dislike itemDislikeToggle">
              <svg width="24" height="24" viewBox="0 0 24 24" class="icon-thumb-inactive">
                  <path d="M19,15V3H23V15H19M15,3A2,2 0 0,1 17,5V15C17,15.55 16.78,16.05 16.41,16.41L9.83,23L8.77,21.94C8.5,21.67 8.33,21.3 8.33,20.88L8.36,20.57L9.31,16H3C1.89,16 1,15.1 1,14V12C1,11.74 1.05,11.5 1.14,11.27L4.16,4.22C4.46,3.5 5.17,3 6,3H15M15,5H5.97L3,12V14H11.78L10.65,19.32L15,14.97V5Z" />
              </svg>
              <svg width="24" height="24" viewBox="0 0 24 24" class="icon-thumb-active">
                <path d="M19,15H23V3H19M15,3H6C5.17,3 4.46,3.5 4.16,4.22L1.14,11.27C1.05,11.5 1,11.74 1,12V14A2,2 0 0,0 3,16H9.31L8.36,20.57C8.34,20.67 8.33,20.77 8.33,20.88C8.33,21.3 8.5,21.67 8.77,21.94L9.83,23L16.41,16.41C16.78,16.05 17,15.55 17,15V5C17,3.89 16.1,3 15,3Z" />
              </svg>
          </div>
          <div class="result-item__shortlist itemShortlistToggle">
              <svg width="24" height="24" viewBox="0 0 24 24" class="icon-heart-plus">
                  <path d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z" />
              </svg>
              <svg width="24" height="24" viewBox="0 0 24 24" class="icon-heart-minus">
                <path d="M12 18C12 19 12.25 19.92 12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C19.83 12.23 18.94 12 18 12C14.69 12 12 14.69 12 18M14 17V19H22V17H14Z" />
              </svg>
          </div>
        </div>
        <a href="/my-curation/item/?id=${ categoryItems[i].ref }" class="result-item__content" style="background-image:url(https://res.cloudinary.com/curobe/image/fetch/w_350,h_500,f_auto,q_auto,c_fill/${ categoryItems[i].image })">
          <div class="result-item__title">
            ${ categoryItems[i].title }
            <span class="result-item__make">${ categoryItems[i].brand }</span>
          </div>
        </a>
      </div>`;

      // Hide unavailable item on search results but NOT on shortlist page
      if(document.body.classList.contains('showShortlist')){
          itemListContainer.insertAdjacentHTML('beforeend', itemObject);
      } else {
        if(!categoryItems[i].unavailable){
          itemListContainer.insertAdjacentHTML('beforeend', itemObject);
        }
      }

      if(document.body.classList.contains('validatedUser')){
        if(categoryItems.length - 1 === i) {
          updateShortlistCount();
        }
      }
    }

  }
    

}

// Filter view based on myCuration Data
function filterItems(myCurationData, returnArray){
  
    let result;

    let curation = myCurationData.curation;

    result = items.filter( f => curation.every(e => f.curation.includes(e)));

    // Get ref for each curated item
    let curatedItemsArray = result.map(a => a.ref);

    if(returnArray != undefined){
      // console.log(curatedItemsArray);
      return curatedItemsArray;
    } else {
      populateSearch(result, curatedItemsArray);
    }

}

// Modify Filter Total
function updateFilterCount(){
    var currentFilterTotal = document.querySelectorAll('.filters input[type="checkbox"]:checked').length;
    document.querySelector('.filterTotal').innerHTML = currentFilterTotal;
}

// Wipe all filter information in one go!
function clearFilters(){

  var allFilters = document.querySelectorAll('.filter-pill input');
  let currentItems = document.querySelectorAll('.result-item');
  document.querySelector('[data-item-filters]').setAttribute('data-item-filters', "");

  for (var i = 0; i < currentItems.length; ++i) {
    currentItems[i].removeAttribute('style');
  }
  
  for (var i = 0; i < allFilters.length; i++) {
    allFilters[i].checked = false;
    updateFilterCount();
  }

  let params = new URLSearchParams(window.location.search);
  params.delete('filters');
  history.replaceState(null, null, "?"+params);

  if(document.body.classList.contains('filtersOpen')){
    messageToaster('', `Filters have been reset`, 'success');
  }

}

// Loop through result items
function loopItems(currentItems, currentFiltersArray, supplyMessage){

  let currentItemsCount = currentItems.length;

  // https://stackoverflow.com/questions/53606337/check-if-array-contains-all-elements-of-another-array
  let checker = (arr, target) => target.every(v => arr.includes(v));

  // Current Category
  let currentCategory = document.getElementById('categorySelector').value;

  for (var i = 0; i < currentItems.length; ++i) {

    if(currentItems[i].getAttribute('data-category') != currentCategory && !document.body.classList.contains('showShortlist')){
      currentItems[i].setAttribute('style', 'display: none;');
    } else {
      if(!currentFiltersArray){
        currentItems[i].removeAttribute('style');
      } else {
        let itemFilters = currentItems[i].getAttribute('data-filters');
        if(checker(itemFilters, currentFiltersArray)){
          currentItems[i].removeAttribute('style');
        } else {
          currentItems[i].setAttribute('style', 'display: none;');
        }
      }
      
    }

  }

  let resultItems = document.querySelectorAll('.result-item[style="display: none;"]');
  let resultItemsCount = resultItems.length;

  if(supplyMessage == true){

    if(currentItemsCount == resultItemsCount){

      messageToaster('', 'No items to show for that filter combination...', 'success');

    } else {

      let totalItems = currentItemsCount - resultItemsCount;
      let resultString = 'items match';

      if(totalItems == 1){
        resultString = 'item matches';
      }

      messageToaster('', `${totalItems} ${resultString} those filters`, 'success');
    }

  }

}

function dislikeShortlistSync(data) {
  return fetch("/.netlify/functions/dislikeShortlistSync", {
      body: JSON.stringify(data),
      method: "POST"
  }).then(response => {
      return response.json()
  })
}

// Event Listeners - Change
document.addEventListener("change", function(e){

  // Toggle Curated vs Uncurated View
  if(e.target.matches('.my-curation-toggle #myCuration')){

    let myCurationData = JSON.parse(localStorage.getItem("myCuration"));
    let resultItems = document.querySelector('.result-items');

    resultItems.innerHTML = '';

    if(e.target.checked){

      filterItems(myCurationData);
      myCurationData['curatedFilter'] = 'true';
      saveData(myCurationData);
      clearFilters()

    } else {

      let curatedItems = filterItems(myCurationData, true);
      populateSearch(items, curatedItems);
      myCurationData['curatedFilter'] = 'false';
      saveData(myCurationData);
      clearFilters()

    }
    
  }

  // Modify Main Curation Category
  if(e.target.matches('#categorySelector')){

    let value = e.target.value;

    document.body.classList.remove('dresses', 'trousers');
    document.body.classList.add(value);

    let filterChecks = document.querySelectorAll('.filters input[type="checkbox"]');
    for (i = 0; i < filterChecks.length; ++i) {
      filterChecks[i].checked = false;
    }

    let checkCurationStatus = 'unregistered';

    // Check to see if localStorage item present and if true set the correct state linked to the toggle switch
    if(localStorage.getItem("myCuration") != null && localStorage.getItem("myCuration")){
      checkCurationStatus = JSON.parse(localStorage.getItem("myCuration"));
      checkCurationStatus = checkCurationStatus.curatedFilter;
    }
    

    if(checkCurationStatus == 'true'){
      filterItems(myCurationData);
    }
    if(checkCurationStatus == 'false'){
      let curatedItems = filterItems(myCurationData, true);
      populateSearch(items, curatedItems);
    }
    if(checkCurationStatus == 'unregistered'){
      populateSearch(items);
    }

    
    // https://zgadzaj.com/development/javascript/how-to-change-url-query-parameter-with-javascript-only
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set("category", value);
    history.replaceState(null, null, "?"+queryParams.toString());
    clearFilters();
    updateFilterCount();
  }

})

// Click Listeners
document.addEventListener("click", function(e){

  // Blocker for shortlist button if not a vlidated user
  if(e.target.closest('.item-shortlist .button')){
    if(!document.body.classList.contains('validatedUser')){
      // alert('Validate your email to start shortlisting or removing items!');
      messageToaster('', 'Please supply an email address on <a href="/my-curation/#summary">your profile</a> to shortlist/downvote', 'neutral');
      e.preventDefault();

    }
  }

  // Blocker for my curation toggle if not a vlidated user
  if(e.target.closest('.my-curation-toggle')){
    if(!document.body.classList.contains('savedUser')){
      messageToaster('', 'Create a <a href="/my-curation/">My Curation profile</a> to view your curated items!', 'neutral');
      // alert('Create a My Curation profile to view your personalised items!')
    }
  }

  // Handle dislike button/toggle + save to DB
  if(e.target.closest('.itemDislikeToggle')){

    if(document.body.classList.contains('validatedUser')){

      let clickedItem = e.target.closest('[data-item-ref]');
      let clickedItemId = clickedItem.getAttribute("data-item-ref");
      clickedItem.classList.remove('shortListed');
      clickedItem.classList.toggle('disLiked');

      if(clickedItem.classList.contains('disLiked')){

        myCurationData.disliked.push(clickedItemId);
        myCurationData.shortlist = myCurationData.shortlist.filter(item => item !== clickedItemId);
        localStorage.setItem('myCuration', JSON.stringify(myCurationData));
        updateShortlistCount();

      } else {

        myCurationData.disliked = myCurationData.disliked.filter(item => item !== clickedItemId);
        localStorage.setItem('myCuration', JSON.stringify(myCurationData));
        updateShortlistCount();

      }

      let updatedDateTime = new Date();
      let disliked = JSON.stringify(myCurationData.disliked);
      let shortlist = JSON.stringify(myCurationData.shortlist);

      // DB Function
      let userData = {
        updated: `${updatedDateTime}`,
        disliked: `${disliked}`,
        shortlist: `${shortlist}`,
        uid: `${myCurationData.uid}`
      }

      dislikeShortlistSync(userData).then((response) => {
        console.log('Data Synced');
      }).catch((error) => {
        // Removed because of odd 'Error: SyntaxError: Unexpected token D in JSON at position 0' console log
        // console.log(`Error: ${error}`);
      })
      
    } else {

      // alert('Validate your email to start shortlisting or disliking items!');
      messageToaster('', 'Please supply an email address on <a href="/my-curation/#summary">your profile</a> to shortlist/downvote', 'neutral');

    }

    e.preventDefault();

  }

  // Handle shortlist button/toggle + save to DB
  if(e.target.closest('.itemShortlistToggle')){

    if(document.body.classList.contains('validatedUser')){

      let clickedItem = e.target.closest('[data-item-ref]');
      let clickedItemId = clickedItem.getAttribute("data-item-ref");

      clickedItem.classList.remove('disLiked');
      clickedItem.classList.toggle('shortListed');

      if(clickedItem.classList.contains('shortListed')){

        myCurationData.shortlist.push(clickedItemId);
        myCurationData.disliked = myCurationData.disliked.filter(item => item !== clickedItemId);
        localStorage.setItem('myCuration', JSON.stringify(myCurationData));
        updateShortlistCount();

      } else {

        myCurationData.shortlist = myCurationData.shortlist.filter(item => item !== clickedItemId);
        localStorage.setItem('myCuration', JSON.stringify(myCurationData));
        updateShortlistCount();

      }

      let updatedDateTime = new Date();
      let disliked = JSON.stringify(myCurationData.disliked);
      let shortlist = JSON.stringify(myCurationData.shortlist);

      // DB Function
      let userData = {
        updated: `${updatedDateTime}`,
        disliked: `${disliked}`,
        shortlist: `${shortlist}`,
        uid: `${myCurationData.uid}`
      }

      dislikeShortlistSync(userData).then((response) => {
        console.log('Data Synced');
      }).catch((error) => {
        // Removed because of odd 'Error: SyntaxError: Unexpected token D in JSON at position 0' console log
        // console.log(`Error: ${error}`);
      })

    } else {

      // alert('Validate your email to start shortlisting or disliking items!');
      messageToaster('', 'Please supply an email address on <a href="/my-curation/#summary">your profile</a> to shortlist/downvote', 'neutral');

    }

    e.preventDefault();
  }

  // Open/close filter sidebar
  if(e.target.closest('.filterToggle')){
    document.body.classList.toggle('filtersOpen');
    e.preventDefault();
  }

  // Filter option pill toggles
  if(e.target.closest('.filter-pill input')){

    const itemFilterEl = document.querySelector('[data-item-filters]');
    let currentItems = document.querySelectorAll('.result-item');
    let currentFilters = itemFilterEl.getAttribute('data-item-filters');

    if(currentFilters == undefined){
      currentFilters = "";
    }

    let nameofFilter = e.target.name;

    let currentFiltersArray = currentFilters.split(' ');
    
    if(e.target.checked){

      currentFiltersArray.push(`${nameofFilter}`);
      loopItems(currentItems, currentFiltersArray, true);

    } else {

      currentFiltersArray = currentFiltersArray.filter(item => item !== `${nameofFilter}`);
      loopItems(currentItems, currentFiltersArray, true);

    }

    currentFiltersArray = currentFiltersArray.join(' ');
    currentFiltersArray = currentFiltersArray.trim();
    updateFilterQuery(currentFiltersArray);
    itemFilterEl.setAttribute('data-item-filters', currentFiltersArray);

    updateFilterCount();
  }

  if(e.target.closest('.removeFilters')){
    clearFilters();
  }

})

function updateFilterQuery(currentFiltersArray){
  let params = new URLSearchParams(window.location.search);
  if(currentFiltersArray != ""){
    params.set('filters', currentFiltersArray);
  } else {
    params.delete('filters');
  }
  history.replaceState(null, null, "?"+params);
  
}

// Used to configure back link on Shortlisting and Item pages - goes back to search if that's where the user came from, if not, then default to dresses
function backButtonFromResultsCheck(){
  let previousUrl = document.referrer;
  let urlSearch = "/search/?category";
  if(previousUrl.indexOf(urlSearch) > -1){
    return true;
  } else {
    return false;
  }
}

// Back link handling for shortlist
if(document.body.classList.contains('showShortlist')){
  if(backButtonFromResultsCheck() == false){
    document.querySelector('.closeShortlist').href = '/my-curation/search/?category=dresses';
    document.querySelector('.item-shortlist .button').href = '/my-curation/search/?category=dresses';
  }
}

// Back link handling for item - includes return to shortlist modification
if(document.body.classList.contains('item')){
  if(backButtonFromResultsCheck() == false){
    let backLink = document.querySelector('.crumb-trail__back');
    let previousUrl = document.referrer;
    let urlSearch = "/my-curation/shortlist/";

    if(previousUrl.indexOf(urlSearch) > -1){
      backLink.href = '/my-curation/shortlist/';
      document.querySelector('.crumb-trail__back span').innerHTML = "Back to Shortlist";
    } else {
      backLink.href = '/my-curation/search/?category=dresses';
      document.querySelector('.crumb-trail__back span').innerHTML = "Explore Clothing Items";
    }
    
  } else {
    document.querySelector('.crumb-trail__back span').innerHTML = "Back to Explore";
  }
}