function updateShortlistCount(){
  
    if(document.body.classList.contains('search')){

      if(myCurationData.shortlist){
        var currentShortlistTotal = myCurationData.shortlist.length;
        document.querySelector('.shortlistTotal').innerHTML = currentShortlistTotal;
      
        let shortlistButton = document.querySelector('.item-shortlist .button');

        if(currentShortlistTotal < 1 && !document.body.classList.contains('showShortlist')){
          shortlistButton.classList.add('button--disabled');
        } else {
          shortlistButton.classList.remove('button--disabled');
        }
        
      }
      
    }
    
}