// Update UI to Returning User View
if (localStorage.getItem("myCuration") != null && localStorage.getItem("myCuration")) {

  // Only flag as a saved user if they have curated items in their localStorage item
    if(myCurationData.curation){

        document.body.classList.add('savedUser');

        let globalCurationLinks = document.querySelectorAll('.myCurationUserLink');
        for (i = 0; i < globalCurationLinks.length; ++i) {
            globalCurationLinks[i].href = '/my-curation/#summary';
        }

    }

    if(myCurationData.uid){
      document.body.classList.add('validatedUser');

      if(document.body.classList.contains('myCurationQuestions')){
        document.getElementById('userId').value = myCurationData.uid;
      }
    }

}

// Rehydrate form detection on MyCuration page
if(document.body.classList.contains('myCurationQuestions')){
  menuStickState("shape");
  populateMyCuration();

  var updatedDateTime = new Date();
  document.getElementById('dataUpdated').value = updatedDateTime;
}

if(params.get('cta')){

  document.body.classList.add('ctaOverlay');

  if(params.get('cta') == 'registrationPending'){
    document.querySelector('.ctaPanel__copy .text').innerHTML = '<strong>Validation Email Sent</strong>Check your email to activate your account link.';
  }

  if(params.get('cta') == 'registrationExistsAlready'){
    document.querySelector('.ctaPanel__copy .text').innerHTML = '<strong>That email address already exists!</strong>Please check your email to access your previously saved \'My Curation\' information.';
  }

  if(params.get('cta') == 'magicLinkPending'){
    document.querySelector('.ctaPanel__copy .text').innerHTML = '<strong>Magic Link Sent!</strong>Please check your email to access your previously saved \'My Curation\' information.';
  }

}

// Allow only numbers and one decimal point on number inputs
function isNumberKey(txt, evt) {
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode == 46) {
    //Check if the text already contains the . character
    if (txt.value.indexOf('.') === -1) {
      return true;
    } else {
      return false;
    }
  } else {
    if (charCode > 31 &&
      (charCode < 48 || charCode > 57))
      return false;
  }
  return true;
}

// Rehydrate form
function populateMyCuration(){

  if (localStorage.getItem("myCuration") != null && localStorage.getItem("myCuration")) {

    myCurationData = JSON.parse(localStorage.getItem("myCuration"));

    if(myCurationData.shoulders){
    let inputEl = document.getElementById(myCurationData.shoulders);
    let inputElName = inputEl.getAttribute("name");
    inputEl.checked = true;
    applyToSummary(myCurationData.shoulders, inputElName);
    }

    if(myCurationData.bust){
    let inputEl = document.getElementById(myCurationData.bust);
    let inputElName = inputEl.getAttribute("name");
    inputEl.checked = true;
    applyToSummary(myCurationData.bust, inputElName);
    }

    if(myCurationData.naturalWaistline){
    let inputEl = document.getElementById(myCurationData.naturalWaistline);
    let inputElName = inputEl.getAttribute("name");
    inputEl.checked = true;
    applyToSummary(myCurationData.naturalWaistline, inputElName);
    }

    for (measureKey in myCurationData) {
    if (!measureKey.startsWith('measure')) continue;
    document.getElementById(measureKey).value = myCurationData[measureKey];
      // Don't add empty values to summary
      if(myCurationData[measureKey] != ""){
        applyToSummary(measureKey, measureKey, 'number');

        // Do imperial conversion on everything but the bust dropdown...
        if(measureKey != 'measureBustCup'){
          // Populate inch values on summary (as well as saved cm ones)
          convertToInches(document.getElementById(measureKey).value, measureKey);
        }

      }
    }

    // As highlights are optional - need to check if there is an array present
    var highlights = myCurationData.highlights;

    if(highlights != ""){
      var highlightsArray = highlights.split(',');

      highlightsArray.forEach(highlightHydrate);

      function highlightHydrate(item){
        document.getElementById(item).checked = true;
        applyToSummary(item, item, "checkbox");
      }
    }
    

  }
}

// Apply saved metric vs imperial preference on MyCuration Question Page
if(localStorage.getItem("metricPreferenceImperial") == 'true' && document.body.classList.contains('myCurationQuestions')){
  document.body.classList.add('showImperial');
  document.getElementById('useImperial').checked = true;
}

// Update Summary Data from Form
function applyToSummary(itemId, itemName, type){
  let newSummaryValue;

  if(type == 'checkbox') {

    if(document.querySelector('.highlightSummaryPlaceholder')){
      document.querySelector('.highlightSummaryPlaceholder').remove();
    }

    let highlightSummaryContainer = document.querySelector('.highlightSummary');
    newSummaryValue = document.querySelector('[data-form-label="' + itemId + '"').innerHTML;
    let newHighlightElement = '<strong class="' + itemName + 'Summary">' + newSummaryValue + '</span>';
    highlightSummaryContainer.insertAdjacentHTML('beforeend', newHighlightElement);

  } else {

    if(type == 'number'){
      newSummaryValue = document.getElementById(itemId).value;
    } else {
      newSummaryValue = document.querySelector('[data-form-label="' + itemId + '"').innerHTML;
    }
  
    document.querySelector('.' + itemName + 'Summary').innerHTML = newSummaryValue;

  }
  
}

// Update MyCurationData on form change
function updateMyCuration(itemName, value){
    let myCurationData;
      // Check if localStorage item exisits
      if (localStorage.getItem("myCuration") != null) {
        myCurationData = JSON.parse(localStorage.getItem("myCuration"));
      } else {
        myCurationData = {};
      }
  
      myCurationData[itemName] = value;
      saveData(myCurationData);
}
  
// Clear MyCurationData Object
function deleteMyCurationItem(itemName){
    myCurationData = JSON.parse(localStorage.getItem("myCuration"));
    delete myCurationData[itemName];
    saveData(myCurationData);
}
  
// Save MyCuration data to Object
function saveData(data){
    localStorage.setItem("myCuration", JSON.stringify(data));
}

// Change Listeners
document.addEventListener("change", function(e){

    if(e.target.matches('#measureBustCup')){
        let itemName = e.target.getAttribute('name');
        applyToSummary(itemName, itemName, 'number');
    }

})

// Form Submit Listener
let myCurationForm = document.getElementById('myCurationDetails');

if(myCurationForm){

  myCurationForm.addEventListener('submit', function(e) {

    e.preventDefault();

        if(!document.getElementById('shouldersWider').checked && !document.getElementById('shouldersBalanced').checked && !document.getElementById('shouldersNarrower').checked){

          window.location.href = "#one";
          showValidationAlert();

        } else if(!document.getElementById('bustWider').checked && !document.getElementById('bustSame').checked && !document.getElementById('bustNarrower').checked){

          window.location.href = "#two";
          showValidationAlert();

        } else if(!document.getElementById('naturalWaistlineMore').checked && !document.getElementById('naturalWaistlineLess').checked){

          window.location.href = "#three";
          showValidationAlert();

        } 
        
        // else if(!document.getElementById('highlightBust').checked && !document.getElementById('highlightWaist').checked && !document.getElementById('highlightRear').checked && !document.getElementById('highlightLegs').checked){

        //   window.location.href = "#four";
        //   showValidationAlert();

        // } 
        
        else {

          // Run our SPAM protection...
          grecaptcha.reset();
          grecaptcha.execute();

          // Where the processing stuff used to go - now is triggered by 

        }

  })

}

// Triggered by data-callback on reCAPTCHA field
function returnToken(){

  // Response for the reCAPTCHA widget
  let googletoken = grecaptcha.getResponse();

  // Lock the submit button while we do our thing!
  document.querySelector('#myCurationDetails .button[type="submit"]').setAttribute('disabled', 'disabled');

  var shoulders = document.querySelector('[name="shoulders"]:checked').value;
  var bust = document.querySelector('[name="bust"]:checked').value;
  var naturalWaistline = document.querySelector('[name="naturalWaistline"]:checked').value;
  var measureHeight = document.querySelector('[name="measureHeight"]').value;
  var measureWaist = document.querySelector('[name="measureWaist"]').value;
  var measureHips = document.querySelector('[name="measureHips"]').value;
  var measureBust = document.querySelector('[name="measureBust"]').value;
  var measureBustCup = document.querySelector('[name="measureBustCup"]').value;

  let highlights = [];

  if(document.getElementById('highlightBust').checked){
    highlights.push(document.querySelector('[name="highlightBust"]').value);
  }
  if(document.getElementById('highlightWaist').checked){
    highlights.push(document.querySelector('[name="highlightWaist"]').value);
  }
  if(document.getElementById('highlightRear').checked){
    highlights.push(document.querySelector('[name="highlightRear"]').value);
  }
  if(document.getElementById('highlightLegs').checked){
    highlights.push(document.querySelector('[name="highlightLegs"]').value);
  }

  highlights = highlights.filter(function(element) {
      return element !== undefined;
  });

  // Build LocalStorage before submitting to register/progressing to Explore
  let localStorageMyCurationData = {
    shoulders: `${shoulders}`,
    bust: `${bust}`,
    naturalWaistline: `${naturalWaistline}`,
    highlights: `${highlights}`,
    measureHeight: `${measureHeight}`,
    measureWaist: `${measureWaist}`,
    measureHips: `${measureHips}`,
    measureBust: `${measureBust}`,
    measureBustCup: `${measureBustCup}`,
    updated: `${updatedDateTime}`,
    token: `${googletoken}`
  }

  // Check for UID
  let userId = document.getElementById('userId').value;
  // console.log('User ID:' + userId + '');

  // If there is a UID add it to the localStorage data
  if(userId != ''){
    localStorageMyCurationData.uid = document.querySelector('[name="userId"]').value;
    localStorageMyCurationData.shortlist = myCurationData.shortlist;
    localStorageMyCurationData.disliked = myCurationData.disliked;
  }

  function myCurationArray(data) {
    return fetch("/.netlify/functions/buildMyCuration", {
        body: JSON.stringify(data),
        method: "POST"
    }).then(response => {
        return response.json()
    })
  }

  // Calculate our myCuration Array
  myCurationArray(localStorageMyCurationData).then((response) => {

    // Get rid of our Google Token
    delete localStorageMyCurationData.token;

    localStorageMyCurationData.curation = response;
    
    localStorage.setItem("myCuration", JSON.stringify(localStorageMyCurationData));

    // Sanitise Email Address
    let checkEmailValue = document.getElementById('saveEmail').value;
    checkEmailValue = checkEmailValue.trim();

    // if Email and UID NOT Present
    if(checkEmailValue == '' && userId == ''){

      // console.log('User NOT Registering');
      // Populate LocalStorageItem and forward them to the curation
      window.location.assign('' + appBaseProtocol + '//' + appBaseUrl + '/my-curation/search/?category=dresses');

    } else {

      // console.log('User IS Registering or Saving');
      document.getElementById('myCurationArray').value = JSON.stringify(response);
      document.getElementById('myCurationDetails').submit();

    }

  }).catch((error) => {
    console.log(error)
  })
  
}

function showValidationAlert(){
  // alert('Looks like we\'re missing a response for one of the required questions! You can quickly check and edit your answers under \'My Profile\'.');
  messageToaster('', 'Please answer the first 3 steps of the form...', 'neutral');
}

// Magic Link Processor
function magicLinkValidation(data) {
  return fetch("/.netlify/functions/magicLinkValidation", {
      body: JSON.stringify(data),
      method: "POST"
  }).then(response => {
      //console.log(response);
      //console.log(response.status);
      return response.status;
  })
}

// Click Listeners
document.addEventListener("click", function(e){

  if(e.target.matches('#myShapeMagicLinkForm .button')){

    let magicLinkEmail = document.querySelector('#msEmail').value;
    document.querySelector('#myShapeMagicLinkForm .button').setAttribute('disabled', 'disabled');

    if(magicLinkEmail != ''){

      let magicLinkData = {
          email: magicLinkEmail
      }

      magicLinkValidation(magicLinkData).then((response) => {

        if(response == 200){
          window.location.replace('' + window.location.protocol + '//' + window.location.host + '/my-curation/?cta=magicLinkPending&type=success');
        } else {
          window.location.replace('' + window.location.protocol + '//' + window.location.host + '/my-curation/?message=magicLinkEmailError&type=magicLinkLookupFail');
        }
        
      }).catch((error) => {
        console.log(`Response error ${error}`);

        window.location.replace('' + window.location.protocol + '//' + window.location.host + '/my-curation/?message=magicLinkEmailError&type=magicLinkLookupFail');
      })

    } else {
      window.location.replace('' + window.location.protocol + '//' + window.location.host + '/my-curation/?message=magicLinkEmailError');
    }

  }
  
  if(e.target.matches('.deleteData')){

    let confirmDeleteWindow = document.querySelector('#infoPanelDeleteData');
    confirmDeleteWindow.classList.remove('validated', 'unvalidated');
    document.querySelector('#deleteAccountEmail').value = "";

    if(localStorage.getItem("myCuration") != null){
      if(myCurationData.uid){
        confirmDeleteWindow.classList.add('validated');
      } else {
        confirmDeleteWindow.classList.add('unvalidated');
      }
    } else {
      confirmDeleteWindow.classList.add('unvalidated');
    }

    e.preventDefault();

  };

  if(e.target.matches('#confirmDeleteData')){

    let deleteConfEmail = document.querySelector('#deleteAccountEmail').value;
    if(deleteConfEmail != ''){

      let deleteConfEmailData = {
        email: deleteConfEmail
      }

      deleteLinkValidation(deleteConfEmailData).then((response) => {

        if(response == 200){
          // Don't give any other message if email failed - just delete localStorage item
          deleteMyCurationLocalStorage();
        } else {
          // Don't give any other message if email failed - just delete localStorage item
          deleteMyCurationLocalStorage();
        }

      }).catch((error) => {
        // Don't give an error message if email failed - just delete localStorage item
        deleteMyCurationLocalStorage();
      })

      
    } else {
      deleteMyCurationLocalStorage();
    }
    e.preventDefault();
  }

  function deleteMyCurationLocalStorage(){
    localStorage.removeItem("myCuration");
    window.location.replace('' + appBaseProtocol + '//' + appBaseUrl + '/my-curation/?message=curationDataCleared');
  }

  // Delete Link Processor
  function deleteLinkValidation(data) {
    return fetch("/.netlify/functions/deleteConfEmail", {
        body: JSON.stringify(data),
        method: "POST"
    }).then(response => {
        return response.status;
    })
  }

  if(e.target.matches('.visitingAgain')){
    document.querySelector('.magic-link-overlay').classList.add('active');
    e.preventDefault();
  }

  if(e.target.matches('.form-label--single-choice-question input[type="radio"]')){
    let itemName = e.target.getAttribute('name');
    let value = e.target.getAttribute('value');
    if(!e.target.classList.contains('supplementary')){
      applyToSummary(value, itemName);
    }
  }; 

  if(e.target.matches('.form-label--single-choice-question input[type="checkbox"]')){
    let itemName = e.target.getAttribute('name');
    let value = e.target.getAttribute('value');

    if(itemName == 'useImperial'){
      
      if(localStorage.getItem("metricPreferenceImperial") == null){

        localStorage.setItem("metricPreferenceImperial", 'true');
        document.body.classList.add('showImperial');

      } else {

        if(localStorage.getItem("metricPreferenceImperial") == 'true'){

          localStorage.setItem("metricPreferenceImperial", 'false');
          document.body.classList.remove('showImperial');

        } else {

          localStorage.setItem("metricPreferenceImperial", 'true');
          document.body.classList.add('showImperial');

        }

      }
    }

    if(e.target.checked){

      if(!e.target.classList.contains('supplementary')){
        applyToSummary(itemName, itemName, "checkbox");
      }

    } else {

      if(!e.target.classList.contains('supplementary')){

        document.querySelector('.' + itemName + 'Summary').remove();

        if(document.querySelector('.highlightSummary').childElementCount == 0){

          document.querySelector('.highlightSummary').insertAdjacentHTML('beforeend', '<strong class="highlightSummaryPlaceholder">none selected</strong>');

        }

      }

    }
  };

})

// Unfocus Listener
document.addEventListener("focusout", function(e){

  if(e.target.matches('input.updateSummaryNumber')){
    let itemName = e.target.getAttribute('name');
    let value = e.target.value;
    if(value != ''){
      applyToSummary(itemName, itemName, 'number');
    }
  }

  if(e.target.id == 'measureHeight' || e.target.id == 'measureWaist' || e.target.id == 'measureHips' || e.target.id == 'measureBust'){
    convertToInches(e.target.value, e.target.id);
  }

  if(e.target.id == 'measureHeightInchesFeet' || e.target.id == 'measureHeightInches' || e.target.id == 'measureWaistInches' || e.target.id == 'measureHipsInches' || e.target.id == 'measureBustInches'){
    convertToCms(e.target.value, e.target.id);
  }

})

function convertToCms(value, fieldId){

  let fieldValueCms;

  if(fieldId == 'measureHeightInchesFeet' || fieldId == 'measureHeightInches'){
    let heightFeet = document.getElementById('measureHeightInchesFeet').value;
    let heightInches = document.getElementById('measureHeightInches').value;
    fieldValueCms = ((heightFeet * 12) * 2.54) + (heightInches * 2.54);

    // Set to default value to allow value to be assigned in single field
    fieldId = 'measureHeightInches';

  } else {

    fieldValueCms = value * 2.54;

  }

  fieldValueCms = fieldValueCms.toFixed(2);

  if(fieldValueCms == 0.00){
    fieldValueCms = '';
  }

  fieldId = fieldId.replace('Inches', '');

  document.getElementById(fieldId).value = fieldValueCms;
  applyToSummary(fieldId, fieldId, 'number');

}

function convertToInches(value, fieldId){
  let fieldValueInches = value / 2.54;

  if(fieldId == 'measureHeight'){

    let feet = fieldValueInches / 12;
    feet = Math.trunc(feet);

    let remainingInches = fieldValueInches - (feet * 12);
    remainingInches = Math.round(remainingInches);

    if(feet == 0){
      feet = '';
    }

    if(remainingInches == 0){
      remainingInches = '';
    }

    document.getElementById('' + fieldId + 'InchesFeet').value = feet;
    document.getElementById('' + fieldId + 'Inches').value = remainingInches;

    // Cover off population of inches summary
    applyToSummary('' + fieldId + 'InchesFeet', '' + fieldId + 'InchesFeet', 'number');
    applyToSummary('' + fieldId + 'Inches', '' + fieldId + 'Inches', 'number');

  } else {

    fieldValueInches = fieldValueInches.toFixed(2);

    if(fieldValueInches == 0.00){
      fieldValueInches = '';
    }

    document.getElementById('' + fieldId + 'Inches').value = fieldValueInches;

    // Cover off population of inches summary
    applyToSummary('' + fieldId + 'Inches', '' + fieldId + 'Inches', 'number');

  }

}