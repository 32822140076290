if(localStorage.getItem("curobeCookieAgreement") == null){
    createCookieDiv();
}

function createCookieDiv(){
    var bodytag = document.getElementsByTagName('body')[0];
    var div = document.createElement('div');
    div.setAttribute('id','cookie-law');
    div.innerHTML = '<p class="wrapper">Curobe uses cookies and browser storage on your device to operate the My Curation personalisation and to analyse traffic and behaviour across the website. To find out more, see our <a href="https://docs.google.com/document/d/1_oVCaCCjYpHZ_o6A_UpLxLThmU-lESS_XMS11J-wwKc/edit?usp=sharing" target="_blank">Cookies & Privacy Policy</a>.<span class="cta"><a href="#" class="button">Okay</a></span></p>';
    bodytag.appendChild(div);
}

// Hide the Cookie Law banner
document.addEventListener("click", function(e){
    if(e.target.matches('#cookie-law .button')){
        localStorage.setItem("curobeCookieAgreement", 'true');
        document.querySelector('#cookie-law').remove();
        e.preventDefault();
    }
})