// Generate Item from URL parameter
if(document.body.classList.contains('item')){
    let itemId = params.get('id');
    let itemIndex = items.findIndex(x => x.ref === itemId);
    if(itemIndex == -1){
      console.log('Item Not Found');
      bounceToHome(appBaseUrl); 
    } else {
      populateItem(itemIndex);
    }
}

function populateItem(id){

    // Add unavailable class to hid store links and make claer that item is not available
    if(items[id].unavailable){
      document.querySelector('body').classList.add('unAvailable');
    }

    // Metadata population
    document.querySelector('title').innerHTML = `${items[id].title} (${items[id].brand}) - Curobe`;
    document.querySelector('meta[name="description"]').setAttribute("content", items[id].description);

    document.querySelector('meta[itemprop="name"]').setAttribute("content", `${items[id].title} (${items[id].brand}) - Curobe`);
    document.querySelector('meta[itemprop="url"]').setAttribute("content", `https://www.curobe.com/my-curation/item/?id=${items[id].ref}`);
    document.querySelector('meta[itemprop="description"]').setAttribute("content", items[id].description);

    document.querySelector('meta[property="og:title"]').setAttribute("content", `${items[id].title} (${items[id].brand})`);
    document.querySelector('meta[property="og:url"]').setAttribute("content", `https://www.curobe.com/my-curation/item/?id=${items[id].ref}`);
    document.querySelector('meta[property="og:description"]').setAttribute("content", items[id].description);

    document.querySelector('meta[name="twitter:title"]').setAttribute("content", `${items[id].title} (${items[id].brand}) - Curobe`);
    document.querySelector('meta[name="twitter:url"]').setAttribute("content", `https://www.curobe.com/my-curation/item/?id=${items[id].ref}`);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", items[id].description);
    
    document.querySelector('[data-populate-ui="category"]').innerText = items[id].category; 
    document.querySelector('[data-populate-ui="category"]').setAttribute("href", `/search/?category=${items[id].category}`);
  
    document.querySelector('[data-populate-ui="title"]').innerText = items[id].title;
    document.querySelector('[data-populate-ui="brand"]').innerText = items[id].brand;
  
    document.querySelector('[data-populate-ui="price"]').innerText = "£" + items[id].price;
    document.querySelector('[data-populate-ui="price"]').setAttribute("href", items[id].link);
  
    document.querySelector('[data-populate-ui="image"]').setAttribute("src", "https://res.cloudinary.com/curobe/image/fetch/w_750,f_auto,q_auto/" + items[id].image);
  
    document.querySelector('[data-populate-ui="fitting"]').innerText = items[id].fitting;
    document.querySelector('[data-populate-ui="description"]').innerText = items[id].description;

    if(myCurationData){
      // lets see if this item is in the curated list
      let curation = myCurationData.curation;
      let result = items.filter( f => curation.every(e => f.curation.includes(e)));
      let match = false;

      // Identify if item is matched as a curated item or not
      for (var i = 0; i < result.length; ++i) {
        if(result[i].ref == items[id].ref){
          match = true;
        }
      }
      
      if(myCurationData.measureBust && myCurationData.measureWaist && myCurationData.measureHips){

        let bust = Number(myCurationData.measureBust);
        let waist = Number(myCurationData.measureWaist);
        let hips = Number(myCurationData.measureHips);

        // console.log(`Bust: ${bust}, Waist: ${waist}, Hips: ${hips}`);

        let itemSizes = items[id].sizes;
        // console.log(itemSizes);

        for (var i = 0; i < items[id].sizes.length; ++i) {

          let matchCount = 0;
          let sizeItem = itemSizes[i].split("|");
          let bustPerc,
              waistPerc,
              hipsPerc;

          if(bust >= sizeItem[1] && bust <= sizeItem[2]){

            bustPerc = percentageCalc(sizeItem[1], sizeItem[2], bust);
            // console.log(`Bust (${sizeItem[0]})/${bust}cm - ${bustPerc}%`);
            matchCount++;

          } else {

            bustPerc = 'no-measurement';

          }

          if(waist >= sizeItem[3] && waist <= sizeItem[4]){

            waistPerc = percentageCalc(sizeItem[3], sizeItem[4], waist);
            console.log(`Waist (${sizeItem[0]})/${waist}cm - ${waistPerc}`);
            matchCount++;

          } else {

            waistPerc = 'no-measurement';

          }

          if(hips >= sizeItem[5] && hips <= sizeItem[6]){

            hipsPerc = percentageCalc(sizeItem[5], sizeItem[6], hips);
            console.log(`Hips (${sizeItem[0]})/${hips}cm - ${hipsPerc}`);
            matchCount++;

          } else {

            hipsPerc = 'no-measurement';

          }

          let sizingItemContainer = document.querySelector('.sizing-items');

          // if(Number.isInteger(bustPerc) || Number.isInteger(waistPerc) || Number.isInteger(hipsPerc)) {
          // Only show sizing guidelines if curated item
          if(match){

            if((matchCount > 1 && items[id].itemCategory == 'dresses') || (matchCount > 0 && items[id].itemCategory == 'trousers')) {
              
              let sizingObject = `<li class="sizing">
                  <span class="sizing__name">${sizeItem[0]}</span>
                  <span class="sizing__range sizing__range--loose">
                      Looser
                  </span>
                  <span class="sizing__bars">
                      <span class="sizing__bar sizing__bar--bust ${bustPerc}">
                          <span class="sizing__value sizing__value--bust" style="width:${bustPerc}%;"></span>
                      </span>
                      <span class="sizing__bar sizing__bar--waist ${waistPerc}">
                          <span class="sizing__value sizing__value--waist" style="width:${waistPerc}%;"></span>
                      </span>
                      <span class="sizing__bar sizing__bar--hips ${hipsPerc}">
                          <span class="sizing__value sizing__value--hips" style="width:${hipsPerc}%;"></span>
                      </span>
                  </span>
                  <span class="sizing__range sizing__range--tighter">
                      Tighter
                  </span>
              </li>`;
              
              sizingItemContainer.insertAdjacentHTML('beforeend', sizingObject);
            }

          }
            
        }

        // Count number of results
        var sizingList = document.querySelector('.sizing-items').getElementsByTagName("li");
        var sizingItemTotal = sizingList.length;

        if(sizingItemTotal > 0){
          document.querySelector('[data-populate-ui="curobeSizingTitle"]').innerText = 'My Curation size recommendation(s)';
          document.querySelector('.curation-size-recommendation').classList.add('active');
        } else {
          if(match){
            document.querySelector('.curation-size-recommendation').classList.add('active');
            document.querySelector('[data-populate-ui="curobeSizingTitle"]').innerHTML = `My Curation size recommendation(s)<br /><br />Oh no, we're so sorry, but based on your supplied information we don't have any sizing recommendations for this curated item.<br /><br />Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSdH7QZna-yDPy2hZd5OXWua0fmVMMIvz1ODKQM-j844EczfWw/viewform" target="_blank">get in touch</a> to let us know that you've had this experience and so we can learn a bit more about you to try to correct this.<br /><br />
            <div style="text-align:center; margin-top:2.4rem;"><a href="https://docs.google.com/forms/d/e/1FAIpQLSdH7QZna-yDPy2hZd5OXWua0fmVMMIvz1ODKQM-j844EczfWw/viewform" target="_blank" class="button">Get in Touch</a></div>`;
          }
        }

      } else {
        // If no measurements but filled in form
        if(match){
          document.querySelector('.curation-size-recommendation').classList.add('active');
            document.querySelector('[data-populate-ui="curobeSizingTitle"]').innerHTML = `My Curation size recommendation(s)<br /><br />To get sizing recommendations for this curated item please provide your measurements on your <a href="/my-curation/#five">My Curation profile</a>:<br /><br />
            <div style="text-align:center; margin-top:2.4rem;"><a href="/my-curation/#five" class="button"> My Curation Profile</a></div>`;
        }
      }

    } else {

      // if(localStorage.getItem("myCuration") != 'null' && localStorage.getItem("myCuration")){
      //   mySizingIntro.innerHTML = 'Please provide your measurements in <a href="/my-curation/">My Curation</a> for sizing recommendations.';
      // }
      
    }

    function percentageCalc(bottomValue, topValue, value){

      bottomValue = Number(bottomValue);
      topValue = Number(topValue);
      value = Number(value);

      let totalRange = topValue - bottomValue;
      let itemValue = value - bottomValue;
      let percentage = (itemValue / totalRange) * 100;
      percentage = Math.round(percentage);

      if(percentage == 0){
        percentage = 5;
      }

      return percentage;
    }
    

    let curationClass;

    if(localStorage.getItem("myCuration")){
      if(myCurationData.disliked){
        if(myCurationData.disliked.includes(`${ items[id].ref }`)){
          curationClass = 'disLiked';
        }
      }
      if(myCurationData.shortlist){
        if(myCurationData.shortlist.includes(`${ items[id].ref }`)){
          curationClass = 'shortListed';
        }
      }
    }

    document.querySelector('[data-item-ref]').setAttribute('data-item-ref', items[id].ref);
    document.querySelector('[data-item-ref]').classList.add(curationClass);
  
    document.querySelector('[data-populate-ui="link"]').setAttribute("href", items[id].link);
  
    let materials = items[id].materials;
    let materialsContainer = document.querySelector('.clothing-item__materials-list');
  
    for(let i = 0; i < materials.length; i++){
      let material = materials[i].replace("material", "");
      material = material.replace(/([A-Z])/g, ' $1').trim();
      materialsObject = `<li>
        <span class="clothing-item__material-title">${ material }</span>
      </li>`
      materialsContainer.insertAdjacentHTML('beforeend', materialsObject);
    }

    let tags = items[id].filters;
    // Get rid of style items
    tags = tags.filter(function (item) {
      return item.indexOf("style") !== 0;
    });

    let tagsContainer = document.querySelector('.clothing-item__tags .tags');

    for(let i = 0; i < tags.length; i++){
      let filterTag = tags[i];
      let filterTagName = "";
      if(filterTag == 'materialOeco'){
        filterTagName = 'Oeko Tex 100 Certified';
      }
      if(filterTag == 'materialGots'){
        filterTagName = 'GOTS Certified';
      }
      if(filterTag == 'materialOrganic'){
        filterTagName = 'Organic Content Standard';
      }
      if(filterTag == 'materialVegan'){
        filterTagName = 'Vegan';
      }
      if(filterTag == 'materialNatural'){
        filterTagName = 'Natural Fibres';
      }
      if(filterTag == 'materialRegenerated'){
        filterTagName = 'Regenerated Fibres';
      }
      if(filterTag == 'materialRecycled'){
        filterTagName = 'Recycled Fibres';
      }
      if(filterTag == 'materialSingle'){
        filterTagName = 'Single Fibre';
      }
      if(filterTag == 'materialExcess'){
        filterTagName = 'Excess Stock';
      }
      if(filterTag == 'manufactureFairtrade'){
        filterTagName = 'Fairtrade Certified';
      }
      if(filterTag == 'manufactureWfto'){
        filterTagName = 'WFTO Member';
      }
      if(filterTag == 'manufactureEurope'){
        filterTagName = 'Made in Europe';
      }
      if(filterTag == 'manufactureUk'){
        filterTagName = 'Made in UK';
      }
      if(filterTag == 'manufactureSa8000'){
        filterTagName = 'SA8000 Certified';
      }
      if(filterTag == 'manufactureCertifiedB'){
        filterTagName = 'Certified B Corporation';
      }
      if(filterTag == 'manufactureMadetoOrder'){
        filterTagName = 'Made to Order';
      }
      if(filterTag == 'manufacturePreOrder'){
        filterTagName = 'Pre-Order';
      }
      tagObject = `<li>${ filterTagName }</li>`
      tagsContainer.insertAdjacentHTML('beforeend', tagObject);
    }

    
  
};

// Click Listeners
document.addEventListener("click", function(e){
  if(e.target.closest('.clothing-item__tab-controls a')){
      
    if(!e.target.classList.contains('active')){
      let viewTab = e.target.getAttribute('data-tab');
      let allTabs = document.querySelectorAll('.clothing-item__tab-controls a');
      let allTabContent = document.querySelectorAll('.clothing-item__tab');

      for (var i = 0; i < allTabs.length; i++) {
        allTabs[i].classList.remove('active')
      }
      for (var i = 0; i < allTabContent.length; i++) {
        allTabContent[i].classList.remove('active')
      }

      e.target.classList.add('active');
      document.querySelector('.clothing-item__tab[data-tab="' + viewTab + '"]').classList.add('active');

    }
    e.preventDefault();
  }
})