// Contact Form: Form processing with recaptcha and messaging
var contactForm = document.getElementById('contactForm');

if(contactForm){
  contactForm.addEventListener('submit', function (event) {
    event.preventDefault();
    document.querySelector('.contactForm__cta .button').setAttribute('disabled', 'disabled');
    grecaptcha.reset();
    grecaptcha.execute();
  });
}

function formSubmit(response) {
  document.getElementById('contactForm').submit();
}