const appBaseUrl = window.location.host;
const appBaseProtocol = window.location.protocol;
let params = new URLSearchParams(window.location.search);
let myCurationData = JSON.parse(localStorage.getItem("myCuration"));
// Bounces User to Homepage
function bounceToHome(appBaseUrl){
  window.location.replace('http://' + appBaseUrl + '/');
}

// Rather naff JS solution for nav sticky state...
function menuStickState(item){}

document.addEventListener("click", function(e){

  if(e.target.closest('.navToggle')){
    document.body.classList.toggle('navOpen');
  }

  if(e.target.closest('.overlayToggle')){
    document.body.classList.toggle('overlayOpen');
    e.preventDefault();
  }

  if(e.target.closest('.inActiveLink')){
    alert('Oops...this part of our site is not quite ready yet!');
    e.preventDefault();
  }

  if(e.target.closest('.button--disabled')){
    e.preventDefault();
  }

  if(e.target.closest('.smoothScroll')){
    let href = e.target.getAttribute("href");
    let offsetTop = document.querySelector(href).offsetTop;
    scroll({
      top: offsetTop,
      behavior: "smooth"
    });
    e.preventDefault();
  }

  if(e.target.closest('.global-nav__item.active')){
    e.preventDefault();
  }
  
});

// Instagram Feed Images
if(document.querySelector('body').classList.contains('home')){
  fetch(`https://graph.instagram.com/me/media?fields=media_url,thumbnail_url,permalink&access_token=${InstagramToken}`)
  .then(response => response.json())
  .then(data => buildGallery(data.data))
  .catch(error => {
      console.error('Error:', error);
  });
}

function buildGallery(data){
    var instaGallery = document.querySelector('.instagallery__grid-block');
    var instaJsonImages = data;
    instaJsonImages.forEach(function(item, i) {
    if(i < 12){
        var thumb = item.media_url;
        if(thumb.includes("https://video")){
          thumb = item.thumbnail_url;
        }
        var instaItemHtml = `<a href="${item.permalink}" target="_blank"><img src="${thumb}" /></a>`;
        instaGallery.insertAdjacentHTML('beforeend', instaItemHtml);
        }
    });
}