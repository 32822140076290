// --- TOASTER --- //

// Look for a message parameter
var message = params.get('message');

if(message === 'failed'){
  messageToaster('', 'There has been a problem, please try again...', 'failed');
}
if(message === 'success'){
  messageToaster('', 'Message sent. We will get back to you as soon as we can.', 'success');
}

if(message === 'betaSuccess'){
  messageToaster('', 'Sign-up Complete. Thanks for your interest in the Beta, we\'ll be in touch!', 'success');
}

if(message === 'newsletterSuccess'){
  messageToaster('', 'Sign-up Complete. Thanks for subscribing to the newsletter!', 'success');
}

if(message === 'confirmAccount'){
  messageToaster('', 'Account details validated - explore your curated items', 'success');
}
if(message === 'rejectAccount'){
  messageToaster('', 'Sorry, there has been a problem - please explore without saving for now', 'failed');
}

if(message === 'accountUpdated'){
  messageToaster('', 'Profile saved - explore your curated items', 'success');
}
if(message === 'accountUpdateFailed'){
  messageToaster('', 'Profile could not be saved - please explore without saving for now', 'failed');
}

if(message === 'curationDataCleared'){
  messageToaster('', 'My Curation form and data has been cleared', 'success');
}

if(message === 'curationDataDeleted'){
  messageToaster('', 'All of your My Curation data has now been deleted', 'success');
}

if(message === 'generalError'){
  messageToaster('', 'Sorry, there has been a problem. Please contact support.', 'failed');
}

if(message === 'magicLinkEmailError'){
  messageToaster('', 'Please check the email address entered is correct/valid', 'failed');
}
 
// Create our message timer
var messagetimer;

function messageToaster(title, message, type) {

  clearTimeout(messagetimer);

  var toaster = document.querySelector('.toaster');

  clearToaster(toaster);

  if(type === 'failed'){
    toaster.classList.add('failed');
  } else if(type === 'neutral') {
    toaster.classList.add('neutral');
  } else {
    toaster.classList.add('success');
  }

  document.querySelector('.toaster__content').innerHTML = message;
  toaster.classList.add('open');

  messagetimer = setTimeout(function() {
      toaster.classList.remove('open');
      let params = new URLSearchParams(window.location.search);
      params.delete('message');
      history.replaceState(null, null, "?"+params);
  }, 4000);

}

function clearToaster(toaster) {
  toaster.classList.remove('failed', 'success', 'neutral');
}

// --- TOASTER END --- //