// Click Listeners
document.addEventListener("click", function(e){

    if(e.target.closest('.infoPanelLaunch')){

        var openPanel = document.querySelector('.infoPanel.open');

        if(openPanel){
            openPanel.classList.remove('open');
        }

        var panelId;

        if(e.target.classList.contains('infoPanelLaunch')){
            panelId = e.target.getAttribute("href");
        } else {
            panelId = e.target.closest('.infoPanelLaunch').getAttribute("href");
        }

        document.querySelector(panelId).classList.add('open');
        e.preventDefault();
        
    }

    if(e.target.closest('.infoPanel__closer')){
        var openPanel = document.querySelector('.infoPanel.open');
        if(openPanel){
            openPanel.classList.remove('open');
        }
        e.preventDefault();
    }


});